'use strict'

const EVENT_CONSTANTS = {
    HOME : {
        ID : 'homepage',
    },
    PLP : {
        ID : 'listing',
        EVENTS : {
            FIRE_FILTERS : 'filter:fire',
            FIRE_SEARCH : 'search:fire',
            FIRE_FIRST_SEARCH : 'search:firefirst',
            FIRE_FILTERS_REMOVE : 'filter-remove:fire',
        }
    },
    PDP : {
        ID : 'details',
        EVENTS : {
            ADD_PRODUCT: 'product:add',
            SELECT_SIZE: 'product:size',
            SELECT_SIZE_CHART: 'product:sizechart',
            SELECT_IMAGE: 'product:image',
            SELECT_DETAILS: 'product:details',
        },
        ASSETS : {
            'AccordionModePayment' : 'modalità di pagamento',
            'AccordionDelivery': 'tempi di consegna e spedizioni',
            'AccordionSafePurchase': 'acquisti sicuri',
        }
    },
    CART : {
        ID : 'cart',
        EVENTS : {
            REMOVE_PRODUCT: 'product:delete',
            REMOVE_PRODUCT_MINICART: 'product:deletefromminicart',
            MINICART_REMOVE_PRODUCT: 'product:minicartdelete',
            MINICART_EXPRESS: 'checkout:express',
            PAYMENT_UPDATE: 'payment:update'
        }
    },
    CHECKOUT : {
        ID : 'checkout',
        STEPS : {
            1 : 'begin_checkout',
            2 : 'view_shipping_info',
            3 : 'view_billing_info',
            4 : 'view_payment_info',
            5 : 'purchase_ga4'
        }
    },
    RETURN : {
        ID : 'return',
    },
    ACCOUNT : {
        ID : 'account',
    },
    WISHLIST : {
        ID : 'wishlist',
        EVENTS : {
            ADD_PRODUCT: 'wishlist:movetocart',
            VIEW_ITEMS : 'wishlist:view',
        },
    },
    GENERAL : {
        TILE_CLICK: 'tile:click',
        OBSERVE_CAROUSEL: 'carousel:observe'
    },
    ERROR : {
        ID : 'error',
    }
};

const ITEM_LIST_NAME = {
    SALES_HOMEPAGE_PDP : 'in offerta',
    CATEGORY_LISTING : 'categoria',
    SALES_LISTING : 'offerte',
    SEARCH_LISTING : 'ricerca',
    SPECIAL_PROMO_LISTING : 'special promo',
    NEW_ARRIVALS_LISTING : 'nuovi arrivi',
    FOR_YOU_LISTING : 'scelti per te',
    SEE_ALSO_PDP : 'vedi anche',
    INTEREST_PDP : 'potrebbe interessarti',
    WISHLIST : 'whishlist',
    DEFAULT : ''
};

const EVENT_LIST_NAME = {
    SELECT_PRODUCT : 'select_item',
    SELECT_ASSET_IMAGE : 'select_promotion',
    VIEW_ASSET_IMAGE : 'view_promotion',
    VIEW_LIST : 'view_item_list',
    VIEW_PRODUCT : 'view_item',
    VIEW_CART : 'view_cart',
    SELECT_SIZE : 'pdp_select_size',
    SELECT_SIZE_CHART : 'pdp_click_size_chart',
    SELECT_IMAGE : 'pdp_click_image',
    SELECT_DETAILS : 'pdp_click_on_details',
    ADD_TO_CART : 'add_to_cart',
    REMOVE_FROM_CART:  'remove_from_cart',
    ADD_SHIPPING_INFO : 'add_shipping_info',
    ADD_BILLING_INFO : 'add_billing_info',
    ADD_PAYMENT_INFO : 'add_payment_info',
    EXPRESS_CHECKOUT : 'fast_checkout',
    USE_FILTER : {
        BASE: 'pdl_filters',
        ADD : 'added',
        REMOVE : 'removed'
    },
    CREATE_RETURN : 'create_return',
    ACCOUNT_LOGIN : 'account_login',
    CLICK_NEWSLETTER : 'click_newsletter'
}

const URL_PARAMS = {
    QR_CODE_REDIRECT : 'qrCodeRedirect',
        FOOTER_PARAM : {
        NAME : 'isFooter',
            VALUE : 'footer'
    },
    HERO_PARAM : {
        NAME : 'isHero',
            VALUE : 'home'
    },
    EVENT : {
        EVENT : 'generic_event',
            EVENT_CATEGORY : 'scan_app_qrcode'
    }
}

const GENERIC_EVENTS = {
    EVENT_NAME : 'generic_event',
    EVENT_CATEGORIES : {
        QR_CODE : 'scan_app_qrcode',
        CTA : 'interaction_cta_app',
        CTA_ICON_NOTICES : 'open_notices_popup',
        POPUP_NOTICES : 'click_notices_popup',
        WISHLIST_PROMO : 'go_to_wishlist_promo',
        MYACCOUNT_OPEN : 'open_myaccount',
        MYACCOUNT_NOTICES : 'open_myaccount_section'
    }
};

const GENERIC_EVENT_ACTION = {
    CTA_ACTIONS : {
        OPEN : 'open',
        CLOSE : 'close',
        CLICK : 'click',
        COUPON : 'coupon',
        WISHLIST : 'wishlist',
        NOTICES : 'notices'
    }
}

module.exports = {
    EVENT_CONSTANTS : EVENT_CONSTANTS,
    ITEM_LIST_NAME : ITEM_LIST_NAME,
    EVENT_LIST_NAME : EVENT_LIST_NAME,
    URL_PARAMS : URL_PARAMS,
    GENERIC_EVENTS : GENERIC_EVENTS,
    GENERIC_EVENT_ACTION : GENERIC_EVENT_ACTION
};
